import { json, LoaderFunction } from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { IntlProvider } from "react-intl";
import { createHead } from "remix-island";
import { getEnv } from "./env.server";

export const loader: LoaderFunction = async () => {
  return json({
    ENV: getEnv(),
  });
};

export const meta = () => [
  { charset: "utf-8" },
  { title: "Tracker" },
  { viewport: "width=device-width,initial-scale=1" },
];

// Styling <link> elements are rendered in entry.server.tsx to help styling flicker on first
// render.  This is due to usage of of this package: https://github.com/Xiphe/remix-island
// which helps with hydration issues in React 18.2.

export const Head = createHead(() => (
  <>
    <Meta />
    <Links />
  </>
));

export default function Root() {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <Head />
      <IntlProvider locale="en">
        <AppProvider i18n={enTranslations}>
          <Outlet />
        </AppProvider>
      </IntlProvider>
      <ScrollRestoration />
      <Scripts />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data.ENV)};`,
        }}
      />
    </>
  );
}
